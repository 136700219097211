import {HelmetProvider, Helmet} from 'react-helmet-async';
import HomeHeader from "./HomeHeader";
import React from "react";
import "./About.css";
import "./spacing-helpers.scss";

function About() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            About
          </title>
          <meta
            name="description"
            content=" About"
          />
        </Helmet>
      </HelmetProvider>
      <HomeHeader></HomeHeader>

      <h1 className="p-t-100">Ovo je About Page</h1>
    </>
  );
}

export default About;
