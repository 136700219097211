import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import "./App.css";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Front from "./Components/Pages/Front";
import ContainersPage from "./Components/Pages/ContainersPage";
import Posts from "./Components/Pages/Posts";
import Token from "./Components/Pages/TokenManager";
import ESPHOME from "./Components/Pages/EsphomeManifest"
import THC from "./Components/Radio/THC";
import Prava from "./Components/Radio/Prava";
import Stations from "./Components/Radio/Stations";
import Login from "./Components/Pages/Login";
import SignUp from "./Components/Pages/SignUp";
import Register from './Components/Pages/Register';

// import DatabaseSetup from "./Components/DatabaseSetup"; // Importuj novu komponentu

// Komponenta za zaštitu ruta
const PrivateRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem("authToken"); // Proveri da li postoji token

  if (!isAuthenticated) {
    // Sačuvaj trenutni URL
    localStorage.setItem("redirectTo", window.location.pathname);
    return <Navigate to="/login" />;
  }

  return element;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Front />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/esphome/releases" element={<ESPHOME />} />
        <Route path="/token" element={<Token />} />
        <Route path="/registerr" element={<Register />} />
        <Route path="/docker" element={<ContainersPage />} />
        <Route path="/front" element={<PrivateRoute element={<Home />} />} />
        <Route path="/posts" element={<PrivateRoute element={<Posts />} />} />
        <Route path="/about" element={<PrivateRoute element={<About />} />} />
        <Route path="/stations" element={<PrivateRoute element={<Stations />} />} />
        <Route path="/stations/thc" element={<PrivateRoute element={<THC />} />} />
        <Route path="/stations/prava" element={<PrivateRoute element={<Prava />} />} />
      </Routes>
    </Router>
  );
}

export default App;






// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import React from "react";
// import "./App.css";
// import Home from "./Components/Pages/Home";
// import About from "./Components/Pages/About";
// import Front from "./Components/Pages/Front";
// import ESPHOME from "./Components/Pages/EsphomeManifest"
// import THC from "./Components/Radio/THC";
// import Prava from "./Components/Radio/Prava";
// import Stations from "./Components/Radio/Stations";
// // import DatabaseSetup from "./Components/DatabaseSetup"; // Importuj novu komponentu

// function App() {

//   return (
//     <Router>
//       <Routes>
//         {/* {!dbSetupComplete ? ( // Ako setup nije završen, prikazuje se DatabaseSetup
//           <Route path="/" element={<DatabaseSetup onSetupComplete={handleSetupComplete} />} />
//         ) : ( */}
//           <>
//             <Route path="/" element={<Front />} />
//             {/* <Route path="/login" element={<Login />} /> */}
//             <Route path="/front" element={<Home />} />
//             <Route path="/about" element={<About />} />
//             <Route path="/stations" element={<Stations />} />
//             <Route path="/stations/thc" element={<THC />} />
//             <Route path="/stations/prava" element={<Prava />} />
//             <Route path="/esphome/releases" element={<ESPHOME />} />
//           </>
        
//       </Routes>
//     </Router>
//   );
// }

// export default App;
