import React, { useState } from "react";
import Radio from "./WSS";
import THCHeader from "./THCHeader";
import "./THC.css";
import aimpicon from "../img/aimp-icon.png";
import ParticlesCanvas from "../Pages/ParticlesCanvas";

function THC() {
  const [isPlayingTechno, setIsPlayingTechno] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);

  const togglePlayPauseTechno = () => {
    if (!hasStarted) {
      setHasStarted(true);
    }
    setIsPlayingTechno((prev) => !prev);
  };

  // Funkcija za slanje podataka o pesmama na backend
  // const sendSongData = async (song, artist) => {
  //   try {
  //     const response = await fetch('http://localhost:3001/api/v2/stations/update', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         station: "techno_chronicle",
  //         song,
  //         artist,
  //       }),
  //     });

  //     const data = await response.json();
  //     console.log('Response from server:', data);
  //   } catch (error) {
  //     console.error('Error sending song data:', error);
  //   }
  // };

  return (
    <>
      <ParticlesCanvas />
      <THCHeader
        isPlayingTechno={isPlayingTechno}
        togglePlayPauseTechno={togglePlayPauseTechno}
        hasStarted={hasStarted}
      />
      <div className="player flex">
      {/* <iframe width="424" height="240" src="https://www.youtube.com/embed/IOn-zMOOvGo" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
        
        <Radio
          radioType="techno"
          isPlayingTechno={isPlayingTechno}
          // sendSongData={sendSongData} // Prosledi funkciju
        />
      </div>
      <div className="download-container">
        <a
          href="https://myradio.bibliotehnika.com/public/techno_chronicle/playlist.m3u"
          download
          className="download-button"
        >
          <img src={aimpicon} alt="AIMP Icon" className="aimp-icon" />
        </a>
      </div>
      <div className="accent-lines">
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
}

export default THC;
