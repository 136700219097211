import React, { useState } from 'react';
import Radio from "./WSS";
import PravaHeader from "./PravaHeader";
import "./Prava.css";
import aimpicon from "../img/aimp-icon.png";
import ParticlesCanvas from "../Pages/ParticlesCanvas"; // Adjust the path as necessary

function Prava() {
  const [isPlayingPrava, setIsPlayingPrava] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);


  const togglePlayPausePrava = () => {
    if (!hasStarted) {
      setHasStarted(true);
    }
    setIsPlayingPrava((prev) => !prev);
  };

  return (
    <>
      {/* Particle Animation Canvas */}
      <ParticlesCanvas />
      <PravaHeader
        isPlayingPrava={isPlayingPrava}
        togglePlayPausePrava={togglePlayPausePrava}
        hasStarted={hasStarted}
      />
      <Radio radioType="prava" isPlayingPrava={isPlayingPrava} />

      <div className="download-container">
        <a
          href="https://myradio.bibliotehnika.com/public/prava/playlist.m3u"
          download
          className="download-button"
        >
          <img src={aimpicon} alt="AIMP Icon" className="aimp-icon" />
        </a>
      </div>
      <div className="accent-lines">
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

    </>
  );
}

export default Prava;
