//HomeHeader.js
import { HelmetProvider, Helmet } from "react-helmet-async";
import React, { useState } from "react";
import "./HomeHeader.css";
import { Link } from "react-router-dom";
import logo from "../img/B.webp";

function Header() {
  const [menuVisible, setMenuVisible] = useState(false);

  const handleMenuToggle = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <header className="home-header flex">
      <HelmetProvider>
        <Helmet>
          <title>Bibliotehnika</title>
          <meta
            name="description"
            content="Bibliotehnika - Enjoy your favorite site!"
          />
        </Helmet>
      </HelmetProvider>

      <nav className="header-sadrzaj flex">
        <div className="logo flex">
          <img
            src={logo}
            alt="Logo"
          />
        </div>

        <div className={`nav-menu flex ${menuVisible ? "open" : ""}`}>
        <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/login">Login</Link></li>
            <li><Link to="/stations/thc">THC</Link></li>
            <li><Link to="/stations/prava">Prava</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </div>
        <div
          className={`hamburger ${menuVisible ? "morf" : ""}`}
          aria-controls="nav-menu"
          aria-expanded={menuVisible}
          onClick={handleMenuToggle}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
