import React, { useEffect, useRef } from "react";

const AudioPlayer = ({ source, isPlaying }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play().catch((error) => console.error("Play Error:", error));
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  return (
    <audio ref={audioRef} controls style={{ display: "none" }}>
      <source src={source} type="audio/mpeg" />
    </audio>
  );
};

export default AudioPlayer;
