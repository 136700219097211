import React, { useEffect, useRef } from 'react';
// import './ParticlesCanvas.css'; // Optional: Include if you have specific styles

const ParticlesCanvas = () => {
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const particlesRef = useRef([]);
  const particleCountRef = useRef(0); // Use useRef to persist particleCount across renders
  const animationFrameIdRef = useRef(null); // To track the animation frame ID

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctxRef.current = ctx;

    // Define the Particle class inside useEffect
    class Particle {
      constructor() {
        this.reset();
        this.y = Math.random() * canvas.height;
        this.fadeDelay = Math.random() * 600 + 100;
        this.fadeStart = Date.now() + this.fadeDelay;
        this.fadingOut = false;
        // Set color once to avoid flickering
        this.red = 255 - Math.floor(Math.random() * 255 / 2);
        this.color = `rgba(${this.red}, 255, 255,`;
      }

      reset() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.speed = Math.random() / 5 + 0.1;
        this.opacity = 1;
        this.fadeDelay = Math.random() * 600 + 100;
        this.fadeStart = Date.now() + this.fadeDelay;
        this.fadingOut = false;
      }

      update() {
        this.y -= this.speed;
        if (this.y < 0) {
          this.reset();
        }

        if (!this.fadingOut && Date.now() > this.fadeStart) {
          this.fadingOut = true;
        }

        if (this.fadingOut) {
          this.opacity -= 0.008;
          if (this.opacity <= 0) {
            this.reset();
          }
        }
      }

      draw() {
        const ctx = ctxRef.current;
        ctx.fillStyle = `${this.color} ${this.opacity})`;
        ctx.fillRect(this.x, this.y, 0.4, Math.random() * 2 + 1);
      }
    }

    // Function to calculate the number of particles based on canvas size
    const calculateParticleCount = () => {
      return Math.floor((canvas.width * canvas.height) / 6000);
    };

    // Function to initialize particles
    const initParticles = () => {
      particlesRef.current = [];
      for (let i = 0; i < particleCountRef.current; i++) {
        particlesRef.current.push(new Particle());
      }
    };

    // Animation loop
    const animate = () => {
      const ctx = ctxRef.current;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particlesRef.current.forEach((particle) => {
        particle.update();
        particle.draw();
      });
      animationFrameIdRef.current = requestAnimationFrame(animate);
    };

    // Function to set canvas size and initialize particles
    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      particleCountRef.current = calculateParticleCount();
      initParticles();
    };

    // Debounce function to limit how often a function can fire
    const debounce = (func, delay) => {
      let timeoutId;
      return () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(func, delay);
      };
    };

    // Initial setup
    setCanvasSize();

    // Add event listener to handle window resizing with debounce
    const handleResize = debounce(setCanvasSize, 300);
    window.addEventListener('resize', handleResize);

    // Start the animation
    animate();

    // Cleanup event listener and cancel animation frame on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameIdRef.current);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -1, // Ensure the canvas stays in the background
      }}
    ></canvas>
  );
};

export default ParticlesCanvas;
