import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    try {
      const response = await axios.post("/admin/sign-up", {
        username,
        password,
      });

      // Uspešna registracija
      setSuccess(true);
      const { token } = response.data;
      localStorage.setItem("authToken", token);

      // Proveri da li postoji prethodni URL i preusmeri
      const redirectTo = localStorage.getItem("redirectTo") || "/";
      navigate(redirectTo);
      // Očisti redirectTo nakon preusmeravanja
      localStorage.removeItem("redirectTo");

    } catch (err) {
      setError(err.response ? err.response.data.error : "Greška pri registrovanju.");
    }
  };

  return (
    <div>
      <h2>Sign Up</h2>
      <form onSubmit={handleSignUp}>
        <div>
          <label>Korisničko ime:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Lozinka:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && <p style={{ color: "green" }}>Uspešna registracija!</p>}
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
};

export default SignUp;
