//Home.js
import React, { useEffect } from "react";
import HomeHeader from "./HomeHeader";
import Footer from "../Templates/Footer";
import "./Home.css";
import background from "../img/main_home.webp";
import { initGsapAnimations } from "./gsapAnimations";
import Countdown from './Countdown'; // Dodajemo import za Countdown komponentu
import Posts from './Posts';

function Home() {
  useEffect(() => {
    console.clear();
    initGsapAnimations();
  }, []);

  const currentYear = new Date().getFullYear();
  
  // Definisanje lokalnih linkova za footer
  const footerLinks = [
    { url: "/", text: "Home" },
    { url: "/login", text: "Login" },
    { url: "/about", text: "About" },
    { url: "/stations", text: "Stations" },
  ];

  return (
    <>
      <HomeHeader />

      <div className="wrapper">
        <div className="content">
          <section className="section mamac"></section>
          <section className="section start">
            <div id="" className="p-t-100">
              <h1>It's been...</h1>
              <div className="center-block">
                {/* Koristimo Countdown komponentu ovde */}
                <Countdown targetDate="Aug 25, 2013 12:30:00" />
                <span className="front">
                  <p className="timeRefDays label center-block">days together</p>
                </span>
              </div>
            </div>
          </section>
          <section className="section start-extra"></section>
          <section className="section middle">
          <Posts />
          </section>
          <section className="section middle-more"></section>
          <section className="section middle-extra"></section>
          <section className="section bottom">
            <Footer year={currentYear} links={footerLinks} />
          </section>
        </div>

        <div className="image-container">
          <img src={background} alt="Beautiful landscape" />
        </div>
      </div>
    </>
  );
}

export default Home;
