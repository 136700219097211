import React, { useState, useEffect } from "react";
import NowPlaying from "./NowPlaying";

const WSS = ({ isPlayingTechno, isPlayingPrava, radioType }) => {
  const [nowPlayingTechno, setNowPlayingTechno] = useState(null);
  const [nowPlayingPrava, setNowPlayingPrava] = useState(null);

  useEffect(() => {
    // Učitavanje podataka iz lokalnog skladišta
    const savedTechnoData = localStorage.getItem("nowPlayingTechno");
    const savedPravaData = localStorage.getItem("nowPlayingPrava");

    if (savedTechnoData) setNowPlayingTechno(JSON.parse(savedTechnoData));
    if (savedPravaData) setNowPlayingPrava(JSON.parse(savedPravaData));

    // WebSocket za praćenje trenutne pesme
    const socket = new WebSocket(
      "wss://myradio.bibliotehnika.com/api/live/nowplaying/websocket"
    );

    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          subs: {
            "station:techno_chronicle": { recover: true },
            "station:prava": { recover: true },
          },
        })
      );
    };

    const handleSseData = (ssePayload) => {
      const nowplayingData = ssePayload.data.np;

      if (nowplayingData.station.shortcode === "techno_chronicle") {
        setNowPlayingTechno(nowplayingData);
        localStorage.setItem("nowPlayingTechno", JSON.stringify(nowplayingData));
        // sendSongData(nowplayingData.song, nowplayingData.artist); // Pošalji podatke na backend
      } else if (nowplayingData.station.shortcode === "prava") {
        setNowPlayingPrava(nowplayingData);
        localStorage.setItem("nowPlayingPrava", JSON.stringify(nowplayingData));
        // sendSongData(nowplayingData.song, nowplayingData.artist); // Pošalji podatke na backend
      }
    };

    socket.onmessage = (e) => {
      const jsonData = JSON.parse(e.data);

      if (jsonData.connect?.data) {
        jsonData.connect.data.forEach(handleSseData);
      } else if (jsonData.pub) {
        handleSseData(jsonData.pub);
      }
    };

    return () => socket.close();
  }, []);

  return (
    <div className="station-container radio-container">
      {radioType === "techno" && nowPlayingTechno && (
        <NowPlaying nowPlaying={nowPlayingTechno} isPlaying={isPlayingTechno} />
      )}
      {radioType === "prava" && nowPlayingPrava && (
        <NowPlaying nowPlaying={nowPlayingPrava} isPlaying={isPlayingPrava} />
      )}
    </div>
  );
};

export default WSS;
