import React, { useState } from 'react';
import Radio from './NowPlaying';
import THCHeader from './THCHeader';
import './THC.css';

function THC() {
  const [isPlayingTechno, setIsPlayingTechno] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);

  const togglePlayPauseTechno = () => {
    if (!hasStarted) {
      setHasStarted(true);
    }
    setIsPlayingTechno(prev => !prev);
  };
  
  return (
    <>
      <THCHeader       
        isPlayingTechno={isPlayingTechno} 
        togglePlayPauseTechno={togglePlayPauseTechno}  
        hasStarted={hasStarted} 
      />
      <Radio radioType="techno" isPlayingTechno={isPlayingTechno} />
    </>
  );
};

export default THC;
