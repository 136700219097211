import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('/api/posts');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchPosts();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Posts</h2>
      <ul>
        {posts.map(post => (
          <li key={post.id}>
            <Link to={`admin/post/${post.id}`}>{post.title}</Link>
            <p>{post.body}</p>
            <p>
              <strong>Autor:</strong> {post.username} <br />
              <strong>Datum kreiranja:</strong> {new Date(post.createdDate).toLocaleString()}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Posts;
