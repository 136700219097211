import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ContainersPage.css'; // Uvozimo CSS stilove

const ContainersPage = () => {
    const [containers, setContainers] = useState([]);

    useEffect(() => {
        // Funkcija za dohvat liste kontejnera sa API-ja
        const fetchContainers = async () => {
            try {
                const response = await axios.get('/api/v1/addons/containers');
                setContainers(response.data);
            } catch (error) {
                console.error("Greška prilikom dohvatanja kontejnera:", error);
            }
        };

        fetchContainers();
    }, []);

    const handleStart = async (id) => {
        try {
            await axios.post(`/api/v1/addons/containers/start/${id}`);
            alert(`Kontejner ${id} je pokrenut`);
        } catch (error) {
            console.error("Greška prilikom startovanja kontejnera:", error);
        }
    };

    const handleStop = async (id) => {
        try {
            await axios.post(`/api/v1/addons/containers/stop/${id}`);
            alert(`Kontejner ${id} je zaustavljen`);
        } catch (error) {
            console.error("Greška prilikom zaustavljanja kontejnera:", error);
        }
    };

    const handleRestart = async (id) => {
        try {
            await axios.post(`/api/v1/addons/containers/restart/${id}`);
            alert(`Kontejner ${id} je restartovan`);
        } catch (error) {
            console.error("Greška prilikom restartovanja kontejnera:", error);
        }
    };

    return (
        <div className="container-page">
            <h1>Docker Kontejneri</h1>
            <div className="containers-grid">
                {containers.map(container => (
                    <div className="container-card" key={container.id}>
                        <h2>{container.name}</h2>
                        <p>Status: {container.status}</p>
                        <p>Slika: {container.image}</p>
                        <div className="metrics">
                            <p>CPU: {container.metrics?.cpuUsage ? (container.metrics.cpuUsage / 1000000).toFixed(2) : "N/A"}%</p>
                            <p>Memorija: {container.metrics?.memoryUsage ? (container.metrics.memoryUsage / (1024 * 1024)).toFixed(2) : "N/A"} MB</p>
                        </div>
                        <div className="actions">
                            <button onClick={() => handleStart(container.id)} className="start-btn">▶</button>
                            <button onClick={() => handleStop(container.id)} className="stop-btn">⏹</button>
                            <button onClick={() => handleRestart(container.id)} className="restart-btn">⟳</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ContainersPage;
