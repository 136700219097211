import React, { useState, useEffect } from 'react';

const Countdown = ({ targetDate }) => {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTime = () => {
      const now = new Date();
      const countTo = new Date(targetDate);
      const difference = now - countTo;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTime({ days, hours, minutes, seconds });
    };

    const timerId = setInterval(calculateTime, 1000);
    
    // Cleanup interval on component unmount
    return () => clearInterval(timerId);
  }, [targetDate]);

  return (
    <div>
      <p>Days: {time.days}</p>
      <p>Hours: {time.hours}</p>
      <p>Minutes: {time.minutes}</p>
      <p>Seconds: {time.seconds}</p>
    </div>
  );
};

export default Countdown;
