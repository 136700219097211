import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { FaPlay, FaPause } from "react-icons/fa";
import logo from "../img/Techno Chronicle.webp";

const TechnoHeader = ({ isPlayingTechno, togglePlayPauseTechno, hasStarted }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [scrollingTitle, setScrollingTitle] = useState("Techno Chronicle");
  const [currentArtist, setCurrentArtist] = useState("Artist");
  const [currentSong, setCurrentSong] = useState("Song Title");

  const buttonIcon = !hasStarted ? <FaPlay /> : isPlayingTechno ? <FaPause /> : <FaPlay />;
  const buttonText = !hasStarted ? "Tune In" : isPlayingTechno ? "Pause" : "Play";

  // // Funkcija za slanje podataka o trenutno puštenoj pesmi na backend
  // const sendSongData = async (stationData) => {
  //   try {
  //     const response = await fetch('http://localhost:3002/api/v2/stations/update', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(stationData),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const result = await response.json();
  //     console.log("Odgovor sa servera:", result);
  //   } catch (error) {
  //     console.error("Greška prilikom slanja podataka:", error);
  //   }
  // };

  // Efekat skrolovanja naslova i slanje podataka o pesmi
  useEffect(() => {
    const savedNowPlayingTechno = localStorage.getItem("nowPlayingTechno");
    if (savedNowPlayingTechno) {
      const {
        now_playing: { song },
      } = JSON.parse(savedNowPlayingTechno);
      setCurrentArtist(song.artist);
      setCurrentSong(song.title);

      // Pripremanje podataka za slanje na backend
      // const stationData = {
      //   station: "techno_chronicle",
      //   song: song.title,
      //   artist: song.artist,
      // };

      // Slanje podataka o pesmi na backend
      // sendSongData(stationData);
    }

    if (isPlayingTechno) {
      const fullTitle = `${currentArtist} - ${currentSong} | THC Radio`;
      let position = 0;
      const scrollInterval = setInterval(() => {
        setScrollingTitle(
          fullTitle.slice(position) + " " + fullTitle.slice(0, position)
        );
        position = (position + 1) % fullTitle.length;
      }, 200); // Podesite brzinu skrolovanja

      return () => clearInterval(scrollInterval); // Očisti interval prilikom unmount-a
    } else {
      setScrollingTitle("Techno Chronicle");
    }
  }, [isPlayingTechno, currentArtist, currentSong]);

  const handleMenuToggle = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <header className="techno-header">
      <HelmetProvider>
        <Helmet>
          <title>{scrollingTitle}</title>
          <meta
            name="description"
            content="Techno Chronicle - Enjoy your favorite techno music!"
          />
        </Helmet>
      </HelmetProvider>
      <nav className="header-sadrzaj flex">
        <div className="logo flex">
          <img src={logo} alt="Logo" />
        </div>
        <div className={`nav-menu flex ${menuVisible ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/stations/thc">THC</Link>
            </li>
            <li>
              <Link to="/stations/prava">Prava</Link>
            </li>
          </ul>
        </div>
        <div className="flex">
          <button className="tech-dugme" onClick={togglePlayPauseTechno}>
            {buttonIcon} <span>{buttonText}</span>
          </button>
          <div
            className={`hamburger ${menuVisible ? "morf" : ""}`}
            aria-controls="nav-menu"
            aria-expanded={menuVisible}
            onClick={handleMenuToggle}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default TechnoHeader;
