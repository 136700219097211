import React, { useState } from 'react';
import axios from 'axios';

const TokenManager = () => {
  const [userId, setUserId] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [token, setToken] = useState('');

  const handlePermissionChange = (permission) => {
    setPermissions((prevPermissions) =>
      prevPermissions.includes(permission)
        ? prevPermissions.filter((p) => p !== permission)
        : [...prevPermissions, permission]
    );
  };

  const handleGenerateToken = async () => {
    try {
      const response = await axios.post('/generate-token', {
        userId,
        permissions,
      });
      setToken(response.data.token);
    } catch (error) {
      console.error("Greška pri generisanju tokena:", error);
    }
  };

  return (
    <div>
      <h2>Kreiraj prilagođeni token</h2>
      <input
        type="text"
        placeholder="User ID"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
      />
      <div>
        <label>
          <input
            type="checkbox"
            onChange={() => handlePermissionChange('viewRoutes')}
          />
          Pristup pregledanju ruta
        </label>
        <label>
          <input
            type="checkbox"
            onChange={() => handlePermissionChange('editRoutes')}
          />
          Pristup uređivanju ruta
        </label>
        {/* Dodajte više prava pristupa po potrebi */}
      </div>
      <button onClick={handleGenerateToken}>Generiši Token</button>
      {token && (
        <div>
          <h3>Vaš token:</h3>
          <textarea readOnly value={token} rows="5" cols="50" />
        </div>
      )}
    </div>
  );
};

export default TokenManager;
