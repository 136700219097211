import React, { useState } from 'react';
import axios from 'axios';
import './EsphomeManifest.css';

const EsphomeManifest = () => {
  // Početne vrednosti za unos podataka
  const [formData, setFormData] = useState({
    name: '',  // Početni naziv uređaja
    version: '1.0.0',  // Početna verzija
    versions: [  // Početne verzije i build-ovi
      {
        version: '2024.10.2',
        builds: [
          {
            chipFamily: 'ESP32',
            ota: {
              md5: '123abc456def',
              path: '/ota/path/to/file',
              release_url: 'http://example.com/ota_release',
              summary: 'Initial release'
            },
            ip: '',
            friendly_name: '',
            mac: '3c71bf2caec5',
            platform: 'ESP32',
            board: 'esp32',
            network: 'wifi',
            config: ''
          }
        ]
      }
    ]
  });

  // Handleri za promenu stanja
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleVersionChange = (index, e) => {
    const { name, value } = e.target;
    const newVersions = [...formData.versions];
    newVersions[index][name] = value;
    setFormData({ ...formData, versions: newVersions });
  };

  const handleBuildChange = (versionIndex, buildIndex, e) => {
    const { name, value } = e.target;
    const newVersions = [...formData.versions];
    newVersions[versionIndex].builds[buildIndex][name] = value;
    setFormData({ ...formData, versions: newVersions });
  };

  // Funkcija za dodavanje novog build-a
  const addNewBuild = (versionIndex) => {
    const newVersions = [...formData.versions];
    newVersions[versionIndex].builds.push({
      chipFamily: '',
      ota: {
        md5: '',
        path: '',
        release_url: '',
        summary: ''
      },
      ip: '',
      friendly_name: '',
      mac: '',
      platform: '',
      board: '',
      network: '',
      config: ''
    });
    setFormData({ ...formData, versions: newVersions });
  };

  // Funkcija za dodavanje nove verzije
  const addNewVersion = () => {
    setFormData({
      ...formData,
      versions: [
        ...formData.versions,
        {
          version: '',
          builds: [
            {
              chipFamily: '',
              ota: {
                md5: '',
                path: '',
                release_url: '',
                summary: ''
              },
              ip: '',
              friendly_name: '',
              mac: '',
              platform: '',
              board: '',
              network: '',
              config: ''
            }
          ]
        }
      ]
    });
  };

  // Funkcija za slanje podataka
const handleSubmit = async (e) => {
  e.preventDefault();

  const deviceName = formData.name; // Dinamički preuzimanje imena uređaja iz forme
  const url = `/api/v1/esphome/${deviceName}/manifest.json`; // Ispravno generisani URL

  try {
    const response = await axios.post(url, formData);
    console.log(response.data); // Uspesno poslati podaci
    alert('Manifest uspešno poslat!');
  } catch (error) {
    console.error('Greška pri slanju podataka:', error);
    alert('Greška pri slanju podataka');
  }
};

const fetchConfig = async (versionIndex, buildIndex) => {
  const { name } = formData;
  if (!name) {
    alert('Unesite vrednost za "name" pre preuzimanja konfiguracije.');
    return;
  }

  const url = `/api/v1/esphome/${name}.yaml`;
  

  try {
    const response = await axios.get(url);
    const newVersions = [...formData.versions];
    newVersions[versionIndex].builds[buildIndex].config = response.data;
    setFormData({ ...formData, versions: newVersions });
  } catch (error) {
    console.error('Greška pri preuzimanju konfiguracije:', error);
    alert('Greška pri preuzimanju konfiguracije');
  }
};

  return (
    <div>
      <h2>Manifest Form</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Version:</label>
          <input
            type="text"
            name="version"
            value={formData.version}
            onChange={handleChange}
            required
          />
        </div>

        {/* Polja za verzije */}
        {formData.versions.map((version, versionIndex) => (
          <div key={versionIndex}>
            <h3>Version {versionIndex + 1}</h3>
            <div>
              <label>Version:</label>
              <input
                type="text"
                name="version"
                value={version.version}
                onChange={(e) => handleVersionChange(versionIndex, e)}
                required
              />
            </div>

            {/* Polja za svaki build */}
            {version.builds.map((build, buildIndex) => (
              <div key={buildIndex}>
                <h4>Build {buildIndex + 1}</h4>

                <div>
                  <label>Chip Family:</label>
                  <input
                    type="text"
                    name="chipFamily"
                    value={build.chipFamily}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                    required
                  />
                </div>

                <div>
                  <label>OTA MD5:</label>
                  <input
                    type="text"
                    name="ota.md5"
                    value={build.ota.md5}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                    required
                  />
                </div>

                <div>
                  <label>OTA Path:</label>
                  <input
                    type="text"
                    name="ota.path"
                    value={build.ota.path}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                    required
                  />
                </div>

                <div>
                  <label>OTA Release URL:</label>
                  <input
                    type="text"
                    name="ota.release_url"
                    value={build.ota.release_url}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                    required
                  />
                </div>

                <div>
                  <label>OTA Summary:</label>
                  <input
                    type="text"
                    name="ota.summary"
                    value={build.ota.summary}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                  />
                </div>

                <div>
                  <label>IP:</label>
                  <input
                    type="text"
                    name="ip"
                    value={build.ip}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                    required
                  />
                </div>

                <div>
                  <label>Friendly Name:</label>
                  <input
                    type="text"
                    name="friendly_name"
                    value={build.friendly_name}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                  />
                </div>

                <div>
                  <label>MAC:</label>
                  <input
                    type="text"
                    name="mac"
                    value={build.mac}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                    required
                  />
                </div>

                <div>
                  <label>Platform:</label>
                  <input
                    type="text"
                    name="platform"
                    value={build.platform}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                    required
                  />
                </div>

                <div>
                  <label>Board:</label>
                  <input
                    type="text"
                    name="board"
                    value={build.board}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                    required
                  />
                </div>

                <div>
                  <label>Network:</label>
                  <input
                    type="text"
                    name="network"
                    value={build.network}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                    required
                  />
                </div>

                <div>
                  <label>Config:</label>
                  <input
                    type="text"
                    name="config"
                    value={build.config}
                    onChange={(e) => handleBuildChange(versionIndex, buildIndex, e)}
                    required
                  />
                </div>

                <button type="button" onClick={() => fetchConfig(versionIndex, buildIndex)}>
                  Fetch Config
                </button>

                <hr />
              </div>
            ))}
            <button type="button" onClick={() => addNewBuild(versionIndex)}>
              Add New Build
            </button>
            <hr />
          </div>
        ))}

        <button type="button" onClick={addNewVersion}>
          Add New Version
        </button>

        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default EsphomeManifest;
