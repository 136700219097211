import React, { useState } from 'react';
import axios from 'axios';
import './Register.css';


const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrors([]);
    setSuccess('');

    try {
      const response = await axios.post('/api/register', {
        username,
        password,
      });

      if (response.status === 200) {
        setSuccess('Registracija uspešna!');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        setErrors(['Došlo je do greške prilikom registracije.']);
      }
    }
  };

  return (
    <div className="register-container">
      <h2>Registracija</h2>
      {success && <p className="success-message">{success}</p>}
      <form onSubmit={handleRegister}>
        <div>
          <label htmlFor="username">Korisničko ime:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Lozinka:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Registruj se</button>
      </form>
      {errors.length > 0 && (
        <ul className="error-messages">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Register;
