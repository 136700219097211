import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { FaPlay, FaPause } from "react-icons/fa";
import logo from "../img/Prava.webp";

const PravaHeader = ({
  isPlayingPrava,
  togglePlayPausePrava,
  hasStarted,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [scrollingTitle, setScrollingTitle] = useState("Prava");
  const [currentArtist, setCurrentArtist] = useState("Artist");
  const [currentSong, setCurrentSong] = useState("Song Title");

  const buttonIcon = !hasStarted ? <FaPlay /> : isPlayingPrava ? <FaPause /> : <FaPlay />;
  const buttonText = !hasStarted ? "Tune In" : isPlayingPrava ? "Pause" : "Play";

  // Efekat skrolovanja naslova
  useEffect(() => {
    // Povlačenje podataka iz localStorage
    const savedNowPlayingPrava = localStorage.getItem('nowPlayingPrava');
    if (savedNowPlayingPrava) {
      const { now_playing: { song } } = JSON.parse(savedNowPlayingPrava);
      setCurrentArtist(song.artist);
      setCurrentSong(song.title);
    }

    if (isPlayingPrava) {
      const fullTitle = `${currentArtist} - ${currentSong} | Prava Radio`;
      let position = 0;
      const scrollInterval = setInterval(() => {
        setScrollingTitle(fullTitle.slice(position) + " " + fullTitle.slice(0, position));
        position = (position + 1) % fullTitle.length;
      }, 200); // Podesite brzinu skrolovanja

      return () => clearInterval(scrollInterval); // Očisti interval prilikom unmount-a
    } else {
      setScrollingTitle("Prava");
    }
  }, [isPlayingPrava, currentArtist, currentSong]);

  const handleMenuToggle = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <header className="prava-header">
      <HelmetProvider>
        <Helmet>
          <title>{scrollingTitle}</title>
          <meta
            name="description"
            content="Prava - Enjoy your favorite deephouse music!"
          />
        </Helmet>
      </HelmetProvider>
      <nav className="header-sadrzaj flex">
        <div className="logo flex">
          <img src={logo} alt="Logo" />
        </div>

        <div className={`nav-menu flex ${menuVisible ? "open" : ""}`}>
        <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/login">Login</Link></li>
            <li><Link to="/stations/thc">THC</Link></li>
            <li><Link to="/stations/prava">Prava</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </div>
        <div className="flex">
          <button className="tech-dugme" onClick={togglePlayPausePrava}>
            {buttonIcon} <span>{buttonText}</span>
          </button>
          <div
            className={`hamburger ${menuVisible ? "morf" : ""}`}
            aria-controls="nav-menu"
            aria-expanded={menuVisible}
            onClick={handleMenuToggle}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default PravaHeader;
