import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Ako koristiš CSS za stilizovanje



const Footer = ({ year, links }) => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; {year} Bibliotehnika. All rights reserved.</p>
        <ul className="footer-links">
          {links.map((link, index) => (
            <li key={index}>
              <Link to={link.url}>
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};


export default Footer;
