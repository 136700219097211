import React from 'react';
import {HelmetProvider, Helmet} from 'react-helmet-async';
import logo from "../img/B.webp";

import './Front.css';
import ParticlesCanvas from './ParticlesCanvas'; // Adjust the path as necessary

  
function Front() {
  const toggleGoldClass = () => {
    document.body.classList.toggle('gold');
  };

  return (
    <>
      {/* Particle Animation Canvas */}
      <ParticlesCanvas />

      <HelmetProvider>
      <Helmet>
        <title>Bibliotehnika</title>
        <meta name="description" content="Bibliotehnika - Enjoy your favorite site!" />
      </Helmet>
      </HelmetProvider>

      <div className="header">
        
        <h2>
          <a href="https://bibliotehnika.com" target="_blank" rel="noopener noreferrer">
          <img
            src={logo}
            alt="Logo"
          />
          </a>
          
        </h2>
        <div className="mid-spot" onClick={toggleGoldClass}></div>
        <button className="contact-btn">
          <span className="glow"></span>
          <span className="contact-btn-content">Contact Us</span>
        </button>

        <div className="spotlight">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="accent-lines">
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="heroSubP">
        <p>Introducing</p>
      </div>

      <div className="hero">
        <div className="heroT">
          <h2>Bibliotehnika</h2>
          <h2>Bibliotehnika</h2>
        </div>
      </div>

      <p className="heroP">
        The world's best platform, <br />
        powered by EclipxOS + React.
      </p>

      <div className="mountains">
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className="hero-spacer"></div>

      <div className="content-section">
        <div className="content-acc">
          <div></div>
          <div></div>
        </div>
        <p className="subt">Revolutionary by design</p>
        <h3 className="title">
          Harness. Empower.<br />
          Unmatched Versatility.
        </h3>
        <p className="subp">
          At the core lies our revolutionary framework, <br />
          ensuring adaptability across all application architectures.
        </p>
      </div>
    </>
  );
}

export default Front;
