import React from "react";
import AudioPlayer from "./AudioPlayer";

const NowPlaying = ({ nowPlaying, isPlaying }) => {
  const source =
    nowPlaying.station.shortcode === "techno_chronicle"
      ? "https://myradio.bibliotehnika.com/listen/techno_chronicle/radio.ogg"
      : "https://myradio.bibliotehnika.com/listen/prava/DAP1";

  return (
    <div className="now-playing">
      {nowPlaying.now_playing.song.art && (
        <img
          src={nowPlaying.now_playing.song.art}
          alt="Album Art"
          width="100"
          height="100"
        />
      )}
      <p>
        {nowPlaying.now_playing.song.title} - {nowPlaying.now_playing.song.artist}
      </p>
      {/* Prosledi isPlaying prop za kontrolu plejera */}
      <AudioPlayer source={source} isPlaying={isPlaying} />
      <div className="listeners">
        <h2>Listeners</h2>
        <p>Current: {nowPlaying.listeners.current}</p>
        <p>Total: {nowPlaying.listeners.total}</p>
        <p>Unique: {nowPlaying.listeners.unique}</p>
      </div>
    </div>
  );
};

export default NowPlaying;
